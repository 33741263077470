import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from 'src/constants/translations';

const resources = {
  en: { translation: {} },
  es: { translation: {} },
  zh: { translation: {} },
};

Object.keys(translations).forEach((string) => {
  Object.keys(translations[string]).forEach((language) => {
    resources[language].translation[string] = translations[string][language];
  });
});

i18n
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
