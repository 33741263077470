import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

import useBreakpoints from 'src/utils/useBreakpoints';
import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import { VACANCY_STATUS } from 'src/models/RoomStore';
import LoadingModal from 'src/components/LoadingModal/LoadingModal';
import ChooseTask from './components/ChooseTask';
import CreateEditTaskForm from './components/CreateEditTaskForm';
import { useManagedConfirmationModal } from 'src/components/ConfirmationModal';

const Container = styled('form')`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  padding: 15px 15px;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    grid-template-columns: minmax(125px, 450px) minmax(125px, 500px);
    grid-gap: 50px;
    padding: 20px 40px;
  }
`;

const CreateEditTaskScreen = observer(
  ({ handleGoBack, task, room, blockFromRoomDetails }) => {
    const { taskStore, hotelStore, roomStore, userStore } = useStore();
    const [assignedUser, setAssignedUsers] = useState(null);
    const [roomNumber, setRoomNumber] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [isRoomBlock, setIsRoomBlock] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
    const { authenticatedUser } = userStore;
    const { languagePreference } = authenticatedUser;
    const createdByUserId = authenticatedUser.id;
    const [mobileStepValue, setMobileStepValue] = useState(0);
    const { isSmallScreen } = useBreakpoints();
    const {
      ConfirmationModal,
      showConfirmationModal,
    } = useManagedConfirmationModal();

    const roomNumberParsed = roomNumber.split(/(?:,| )+/).filter((n) => n);

    const createOneTask = async (payload) => {
      let roomId;

      if (roomNumberParsed.length === 1) {
        const filteredRoom = roomStore.roomsAsArray.filter(
          (obj) => obj.number === roomNumberParsed[0]
        );
        roomId = filteredRoom[0] ? filteredRoom[0].id : null;
      }
      if (isRoomBlock) {
        await roomStore.updateRoom({
          roomId: room ? room.id : roomId,
          options: {
            body: { vacancyStatus: VACANCY_STATUS.BLOCKED },
          },
          shouldThrowError: true,
          errorNotificationText: `Unable to update room to ${VACANCY_STATUS.BLOCKED}. Please try again.`,
        });
      }
      await taskStore.createTask({
        body: {
          task: {
            ...payload,
            createdByUserId,
            roomId: room ? room.id : roomId,
            hotelId: hotelStore.selectedHotelId,
            status: INCOMPLETE_STATUS,
          },
        },
        shouldThrowError: true,
      });
    };

    const createMultipleTasks = async (payload) => {
      const filteredRooms = roomStore.roomsAsArray.filter((item) =>
        roomNumberParsed.includes(item.number)
      );

      if (isRoomBlock) {
        const roomsToBlock = filteredRooms.map((item) => {
          return {
            number: item.number,
            vacancyStatus: 'blocked',
            cleanStatus: item.cleanStatus,
          };
        });

        await roomStore.updateRooms({
          body: roomsToBlock,
          hotelId: hotelStore.selectedHotelId,
          shouldThrowError: true,
        });
      }

      const tasks = filteredRooms.map((item) => {
        const modifiedPayload = {
          ...payload,
          roomId: item.id,
          createdByUserId,
          hotelId: hotelStore.selectedHotelId,
          status: INCOMPLETE_STATUS,
        };
        return modifiedPayload;
      });

      await taskStore.createTasks({
        body: {
          tasks,
        },
        shouldThrowError: true,
      });
    };

    const onSubmit = async (e) => {
      e.preventDefault();

      const descriptionWithLanguages =
        selectedTask && selectedTask.description
          ? selectedTask.description
          : {
              english: taskDescription,
              spanish: taskDescription,
              chinese: taskDescription,
            };

      const payload = {
        description: JSON.stringify(descriptionWithLanguages),
        userIds: [],
        urgent: isUrgent,
        additionalNotes,
      };
      if (assignedUser) {
        payload.userIds.push(assignedUser.id);
      }

      showConfirmationModal({
        variant: 'loading-error-success-only',
        onSuccessModalClose: handleGoBack,
        onConfirm: async () => {
          if (task) {
            await taskStore.patchTask({
              taskId: task.id,
              options: { body: { task: payload } },
              shouldThrowError: true,
            });
          } else if (roomNumberParsed.length > 1) {
            await createMultipleTasks(payload);
          } else {
            await createOneTask(payload);
          }
        },
      });
    };

    useEffect(() => {
      if (selectedTask) {
        setMobileStepValue(1);
      } else {
        setMobileStepValue(0);
      }
    }, [selectedTask]);

    useEffect(() => {
      if (task) {
        setIsUrgent(task.urgent);
        if (task.taskAssignments.length > 0) {
          const assignedId = task.taskAssignments[0].userId;
          const user = userStore.users.get(assignedId);
          setAssignedUsers(user);
        }
        if (task.translatedAdditionalNotes) {
          setAdditionalNotes(task.translatedAdditionalNotes);
        }
        if (task.description) {
          setTaskDescription(task.description[languagePreference]);
          setSelectedTask({
            description: task.description,
            title: task.description[languagePreference],
          });
        }
      }
    }, [task]);

    useEffect(() => {
      if (blockFromRoomDetails) {
        setIsRoomBlock(true);
      }
    }, [blockFromRoomDetails]);

    const shouldDisableInput = !selectedTask && !task;

    const ChooseTaskContent = (
      <ChooseTask
        setTaskDescription={setTaskDescription}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        isRoomBlock={isRoomBlock}
      />
    );
    const FormContent = (
      <CreateEditTaskForm
        room={room}
        roomNumber={roomNumber}
        setRoomNumber={setRoomNumber}
        taskDescription={taskDescription}
        setTaskDescription={setTaskDescription}
        selectedTask={selectedTask}
        additionalNotes={additionalNotes}
        setAdditionalNotes={setAdditionalNotes}
        assignedUser={assignedUser}
        setAssignedUsers={setAssignedUsers}
        isUrgent={isUrgent}
        setIsUrgent={setIsUrgent}
        handleGoBack={handleGoBack}
        roomNumberParsed={roomNumberParsed}
        shouldDisableInput={shouldDisableInput}
        task={task}
        isLoading={isLoading}
        isRoomBlock={isRoomBlock}
        setIsRoomBlock={setIsRoomBlock}
      />
    );
    const LoadingModalContent = isLoadingModalOpen ? (
      <LoadingModal
        onClose={() => setIsLoadingModalOpen(false)}
        onRetry={onSubmit}
        handleGoBack={handleGoBack}
        networkError={taskStore.networkError}
        dismissNetworkError={taskStore.dismissNetworkError}
        task={task}
      />
    ) : null;

    const stepContent = [ChooseTaskContent, FormContent];

    return (
      <>
        <Container onSubmit={onSubmit}>
          {isSmallScreen ? (
            <>
              {stepContent[mobileStepValue]}
              {LoadingModalContent}
            </>
          ) : (
            <>
              {ChooseTaskContent}
              {FormContent}
              {LoadingModalContent}
            </>
          )}
        </Container>
        <ConfirmationModal />
      </>
    );
  }
);

CreateEditTaskScreen.propTypes = {
  handleGoBack: PropTypes.func,
  room: PropTypes.object,
  task: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.object.isRequired,
    urgent: PropTypes.bool,
    taskAssignments: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.number,
      })
    ),
    additionalNotes: PropTypes.string,
  }),
};

export default CreateEditTaskScreen;
