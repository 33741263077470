import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { CLEAN_STATUS, VACANCY_STATUS, COLORS } from 'src/models/RoomStore';
import { ReactComponent as CleanIcon } from 'src/assets/icons/clean.svg';
import { ReactComponent as DirtyIcon } from 'src/assets/icons/dirty.svg';
import { ReactComponent as BlockedIcon } from 'src/assets/icons/blocked.svg';
import { ReactComponent as InspectIcon } from 'src/assets/icons/inspect.svg';
import { ReactComponent as DoNotDisturbIcon } from 'src/assets/icons/do_not_disturb.svg';

export const DO_NOT_DISTURB = 'Do Not Disturb';

export const testIds = {
  CLEAN_ICON: 'CLEAN_ICON',
  INSPECT_ICON: 'INSPECT_ICON',
  DIRTY_ICON: 'DIRTY_ICON',
  BLOCKED_ICON: 'BLOCKED_ICON',
  DO_NOT_DISTURB_ICON: 'DO_NOT_DISTURB_ICON',
  TRIANGLE: 'TRIANGLE',
};

const HEADER_HEIGHT = 80;

const HEADER_PADDING_TOP = 16;
const HEADER_PADDING_BOTTOM = 12;

const Container = styled('div')`
  display: flex;
  flex: 1;
  position: relative;
  height: ${HEADER_HEIGHT}px;
  max-height: ${HEADER_HEIGHT}px;
  padding-top: ${HEADER_PADDING_TOP}px;
  padding-bottom: ${HEADER_PADDING_BOTTOM}px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: '#ffffff';
`;

const TriangleContainer = styled('div')`
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
`;

const Triangle = styled('div')`
  border-bottom: ${HEADER_HEIGHT + HEADER_PADDING_BOTTOM + HEADER_PADDING_TOP}px
    solid ${(props) => props.color || '#ffffff'};
  border-left: 135px solid transparent;
  margin-left: 60%;
  z-index: 0;
`;

const CleanStatusContainer = styled('div')`
  width: 70px;
  height: 65px;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor || 'white'};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const RoomDetailsHeader = ({
  number,
  doNotDisturb,
  vacancyStatus,
  cleanStatus,
  isGeneralArea,
}) => {
  const getBackgroundColor = () => {
    if (doNotDisturb) {
      return COLORS.DO_NOT_DISTURB;
    }
    if (vacancyStatus.toLowerCase() === VACANCY_STATUS.BLOCKED) {
      return COLORS.BLOCKED;
    }

    switch (cleanStatus.toLowerCase()) {
      case CLEAN_STATUS.CLEAN:
        return COLORS.CLEAN;
      case CLEAN_STATUS.DIRTY:
        return COLORS.DIRTY;
      case CLEAN_STATUS.SHOULD_INSPECT:
        return COLORS.SHOULD_INSPECT;
      default:
        return 'white';
    }
  };

  const getSubheaderIcon = () => {
    const ICON_WIDTH = 16;
    const ICON_HEIGHT = 16;
    const FILL = '#666666';
    if (doNotDisturb) {
      return (
        <DoNotDisturbIcon
          data-testid={testIds.DO_NOT_DISTURB_ICON}
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          fill={FILL}
          style={{ marginRight: 8 }}
        />
      );
    }
    if (vacancyStatus.toLowerCase() === VACANCY_STATUS.BLOCKED) {
      return (
        <BlockedIcon
          data-testid={testIds.BLOCKED_ICON}
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          fill={FILL}
          style={{ marginRight: 8 }}
        />
      );
    }
    return null;
  };

  const getCleanStatusIcon = () => {
    const ICON_WIDTH = 24;
    const ICON_HEIGHT = 24;
    const FILL = '#000000';
    switch (cleanStatus.toLowerCase()) {
      case CLEAN_STATUS.CLEAN:
        return (
          <CleanIcon
            data-testid={testIds.CLEAN_ICON}
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            fill={FILL}
          />
        );
      case CLEAN_STATUS.SHOULD_INSPECT:
        return (
          <InspectIcon
            data-testid={testIds.INSPECT_ICON}
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            fill={FILL}
          />
        );
      case CLEAN_STATUS.DIRTY:
        return (
          <DirtyIcon
            data-testid={testIds.DIRTY_ICON}
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            fill={FILL}
          />
        );
      default:
        return null;
    }
  };

  const getCleanStatusText = () => {
    if (cleanStatus === CLEAN_STATUS.SHOULD_INSPECT) {
      return 'Inspect';
    }
    return (
      cleanStatus.charAt(0).toUpperCase() + cleanStatus.slice(1).toLowerCase()
    );
  };

  return (
    <Container isShouldInspect={cleanStatus === CLEAN_STATUS.SHOULD_INSPECT}>
      <TriangleContainer>
        <Triangle color={getBackgroundColor()} data-testid={testIds.TRIANGLE} />
      </TriangleContainer>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        }}
      >
        <Typography variant="h4">{number}</Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 10,
            marginRight: 5,
          }}
        >
          {!isGeneralArea && (
            <Typography style={{ color: '#666666' }} variant="h5">
              {doNotDisturb
                ? DO_NOT_DISTURB
                : vacancyStatus.charAt(0).toUpperCase() +
                  vacancyStatus.slice(1).toLowerCase()}
            </Typography>
          )}
        </div>
        {getSubheaderIcon()}
      </div>

      <CleanStatusContainer borderColor={getBackgroundColor()}>
        {getCleanStatusIcon()}

        <Typography variant="body2">{getCleanStatusText()}</Typography>
      </CleanStatusContainer>
    </Container>
  );
};

RoomDetailsHeader.propTypes = {
  number: PropTypes.string,
  doNotDisturb: PropTypes.bool,
  vacancyStatus: PropTypes.oneOf(
    Object.keys(VACANCY_STATUS).map((key) => VACANCY_STATUS[key])
  ),
  cleanStatus: PropTypes.oneOf(
    Object.keys(CLEAN_STATUS).map((key) => CLEAN_STATUS[key])
  ),
};

export default RoomDetailsHeader;
