import React from 'react';
import { persist } from 'mst-persist';
import {
  ThemeProvider as MaterialUIThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { createConsumer } from '@rails/actioncable';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { theme } from 'src/constants';
import StoreContext from 'src/context/StoreContext';
import Store from 'src/models/Store';
import ActionCableContext from 'src/context/ActionCableContext';
import LBRouterSwitch from './LBRouterSwitch';

import './i18n';

export const USERS_GET_URL = '/users';
export const GET_HOTEL_INFO_FAILURE_TEXT =
  'There was an error getting hotel information.';

const store = Store.create({});
persist('store', store, {});

const actionCableConsumer = createConsumer(
  `${process.env.REACT_APP_API_HOST.replace('http', 'ws')}/cable`
);

const stylingTheme = createTheme(theme);
stylingTheme.typography.h5 = {
  fontSize: '1.6rem',
};

const App = () => {
  return (
    <ActionCableContext.Provider value={actionCableConsumer}>
      <StoreContext.Provider value={store}>
        <MaterialUIThemeProvider theme={stylingTheme}>
          <CssBaseline />
          <EmotionThemeProvider theme={stylingTheme}>
            <BrowserRouter>
              <LBRouterSwitch />
            </BrowserRouter>
          </EmotionThemeProvider>
        </MaterialUIThemeProvider>
      </StoreContext.Provider>
    </ActionCableContext.Provider>
  );
};
export default App;
