import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';
import moment from 'moment-timezone';
import { request } from '../utils/LodgebookAPIClient';

export const TASKS_URL = '/tasks';
export const INCOMPLETE_STATUS = 'incomplete';
export const COMPLETE_STATUS = 'complete';
export const LATER_STATUS = 'later';
export const CREATE_TASK_URL = '/tasks';

export const TaskAssignment = types.model('TaskAssignment', {
  id: types.identifierNumber,
  userId: types.number,
  taskId: types.number,
});

export const Task = types
  .model('Task', {
    id: types.identifierNumber,
    roomId: types.maybeNull(types.number),
    description: types.frozen({
      english: types.string,
      spanish: types.string,
      chinese: types.string,
    }),
    status: types.enumeration('TaskStatus', [
      INCOMPLETE_STATUS,
      COMPLETE_STATUS,
      LATER_STATUS,
    ]),
    completedAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    assignedAt: types.maybeNull(types.string),
    taskAssignments: types.array(TaskAssignment),
    urgent: types.optional(types.boolean, false),
    additionalNotes: types.maybeNull(types.string),
    additionalNotesTranslations: types.optional(
      types.frozen({
        english: types.string,
        spanish: types.string,
        chinese: types.string,
      }),
      null
    ),
    createdByUserId: types.maybeNull(types.number),
    completedByUserId: types.maybeNull(types.number),
  })
  .views((self) => ({
    get translatedAdditionalNotes() {
      const { userStore } = getRoot(self);
      const { authenticatedUser } = userStore;
      const { languagePreference } = authenticatedUser ?? {};

      if (
        languagePreference &&
        self?.additionalNotesTranslations &&
        self?.additionalNotesTranslations[languagePreference]
      ) {
        return self.additionalNotesTranslations[languagePreference];
      }

      return self.additionalNotes;
    },
    get createdByUser() {
      const { userStore } = getRoot(self);

      const user = userStore?.users?.get(self.createdByUserId);
      if (user) {
        return user;
      }

      return null;
    },
    get completedByUser() {
      const { userStore } = getRoot(self);

      const user = userStore?.users?.get(self.completedByUserId);
      if (user) {
        return user;
      }

      return null;
    },
  }));

const TaskStore = types
  .model('TaskStore', {
    tasks: types.optional(types.map(Task), {}),
    isFetchingAll: types.optional(types.boolean, false),
    tasksToPatch: types.optional(types.array(types.number), []),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get tasksAsArray() {
      return values(self.tasks);
    },
    get completedTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      const filteredTasks = root.taskStore.tasksAsArray.filter((task) => {
        return (
          task.status === COMPLETE_STATUS &&
          (!task.completedAt ||
            moment(task.completedAt)
              .tz(timeZone)
              .isSame(moment().tz(timeZone), 'day'))
        );
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
    tasksForRoomId(roomId) {
      return values(self.tasks).filter(
        (task) => task.roomId && task.roomId === roomId
      );
    },
  }))
  .actions((self) => ({
    fetchAllTasks: flow(function*(hotelId) {
      self.networkError = undefined;
      self.isFetchingAll = true;
      try {
        const tasksResponse = yield request(
          `${TASKS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.tasks = {};
        tasksResponse.tasks.forEach((task) => {
          self.tasks.set(task.id, task);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch tasks', error);
      }
      self.isFetchingAll = false;
    }),
    fetchTask: flow(function*(taskId) {
      try {
        const { task, taskAssignments } = yield request(
          `${TASKS_URL}/${taskId}`,
          'GET'
        );
        task.taskAssignments = taskAssignments;
        self.tasks.set(task.id, task);
      } catch (error) {
        console.log(error);
      }
    }),
    patchTask: flow(function*({
      taskId,
      options,
      errorNotificationText,
      shouldThrowError = false,
    }) {
      let errorToBeThrown;

      self.tasksToPatch.push(taskId);
      try {
        const patchedTask = yield request(`${TASKS_URL}/${taskId}`, 'PATCH', {
          body: options.body,
        });
        const updatedTask = patchedTask.task;
        updatedTask.taskAssignments = patchedTask.taskAssignments;
        self.tasks.set(taskId, updatedTask);
      } catch (error) {
        errorToBeThrown = error;
        self.networkError = JSON.stringify(error);
        console.warn('Failed to update task', error);

        if (!shouldThrowError && errorNotificationText) {
          getRoot(self).notificationStore.createNotification({
            additionalId: taskId,
            additionalType: Task.name,
            text: errorNotificationText,
            callback: () =>
              self.patchTask({ taskId, options, errorNotificationText }),
          });
        }
      }
      const index = self.tasksToPatch.indexOf(taskId);
      if (index !== -1) {
        self.tasksToPatch.splice(index, 1);
      }

      if (shouldThrowError && errorToBeThrown) {
        throw errorToBeThrown;
      }
    }),
    createTask: flow(function*(options) {
      const { shouldThrowError = false } = options;
      let errorToBeThrown;

      try {
        const modifiedPayload = {
          ...options.body,
        };
        const taskResponse = yield request(CREATE_TASK_URL, 'POST', {
          body: { ...modifiedPayload },
        });
        const newTask = taskResponse.task;
        newTask.taskAssignments = taskResponse.taskAssignments;
        self.tasks.set(taskResponse.task.id, newTask);
      } catch (error) {
        errorToBeThrown = error;

        if (!shouldThrowError) {
          self.networkError = JSON.stringify(error);
        }
        console.warn('Failed to create task', error);
      }

      if (shouldThrowError && errorToBeThrown) {
        throw errorToBeThrown;
      }
    }),
    createTasks: flow(function*(options) {
      const { shouldThrowError = false } = options;
      let errorToBeThrown;

      try {
        const modifiedPayload = {
          ...options.body,
        };
        const taskResponse = yield request(CREATE_TASK_URL, 'PUT', {
          body: { ...modifiedPayload },
        });
        taskResponse.tasks.forEach((newTask) => {
          const { task } = newTask;
          task.taskAssignments = newTask.taskAssignments;
          self.tasks.set(task.id, {
            ...task,
          });
        });
      } catch (error) {
        errorToBeThrown = error;

        if (!shouldThrowError) {
          self.networkError = JSON.stringify(error);
        }
        console.warn('Failed to create task', error);
      }

      if (shouldThrowError && errorToBeThrown) {
        throw errorToBeThrown;
      }
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default TaskStore;
