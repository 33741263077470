import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  VACANCY_STATUS,
  CLEAN_STATUS,
  DO_NOT_DISTURB_STATUS,
  sortRoomsByType,
} from 'src/models/RoomStore';
import useBreakpoints from 'src/utils/useBreakpoints';
import RoomsList from 'src/screens/Dashboard/Rooms/RoomScreen/components/RoomsList';
import { useStore } from 'src/context/StoreContext';
import RoomFiltersMobile from 'src/screens/Dashboard/Rooms/RoomScreen/components/RoomFiltersMobile';
import RoomFilters from './components/RoomFilters';
import RoomFiltersContext from './components/RoomFiltersContext';

const constructDefaultFilters = () => {
  const filters = {};
  Object.values(VACANCY_STATUS).forEach((vacancyStatus) => {
    filters[vacancyStatus] = false;
  });
  Object.values(CLEAN_STATUS).forEach((cleanStatus) => {
    filters[cleanStatus] = false;
  });
  filters[DO_NOT_DISTURB_STATUS] = false;
  return filters;
};

const RoomScreen = observer(({ setIsDrawerOpen }) => {
  const store = useStore();
  const [filtersState, setFiltersState] = useState({
    filters: constructDefaultFilters(),
    firstSelectedFilter: null,
  });
  const { isSmallScreen } = useBreakpoints();

  const { filters, firstSelectedFilter } = filtersState;
  const { selectedHotelId } = store.hotelStore;
  const { networkError } = store.roomStore;

  useEffect(() => {
    if (selectedHotelId) {
      store.fetchAll();
    }
  }, [selectedHotelId]);

  /**
   * Only the DoNotDisturb filter can be disabled
   * The DoNotDisturb filter is disabled if both of the following are true:
   * - The stayover filter is off AND
   * - Any OTHER (non-stayover) vacancy status filter is activated
   *
   * */
  const isFilterDisabled = (filterValue) => {
    return (
      filterValue === DO_NOT_DISTURB_STATUS &&
      !filters[VACANCY_STATUS.STAYOVER] &&
      (filters[VACANCY_STATUS.VACANT] ||
        filters[VACANCY_STATUS.CHECKOUT] ||
        filters[VACANCY_STATUS.BLOCKED])
    );
  };

  const clearFilters = () => {
    setFiltersState({
      filters: constructDefaultFilters(),
      firstSelectedFilter: null,
    });
  };

  const toggleFilter = (filterValue) => {
    const newFilters = { ...filters }; // Quick way to do object shallow copy
    newFilters[filterValue] = !newFilters[filterValue];
    Object.values(newFilters).forEach((currentFilterValue) => {
      if (isFilterDisabled(currentFilterValue)) {
        newFilters[currentFilterValue] = false;
      }
    });
    const newFiltersTurnedOn = Object.keys(newFilters).filter(
      (filterKey) => newFilters[filterKey]
    );

    let newFirstSelectedFilter = firstSelectedFilter;
    if (newFiltersTurnedOn.length === 1) {
      [newFirstSelectedFilter] = newFiltersTurnedOn;
    } else if (newFiltersTurnedOn.length === 0) {
      newFirstSelectedFilter = null;
    }

    setFiltersState({
      filters: newFilters,
      firstSelectedFilter: newFirstSelectedFilter,
    });
  };

  const passesFilters = (status, validStatuses) => {
    const allFiltersInactive = !validStatuses.some(
      (validStatus) => filters[validStatus]
    );
    const oneFilterPasses = validStatuses.some((validStatus) => {
      return filters[validStatus] && validStatus === status;
    });
    return allFiltersInactive || oneFilterPasses;
  };

  const { generalAreas, rooms } = useMemo(() => {
    const {
      generalAreas: tempGeneralAreas,
      rooms: tempRooms,
    } = sortRoomsByType(store.roomsWithTaskProperties ?? []);

    return {
      generalAreas: tempGeneralAreas,
      rooms: tempRooms.filter((room) => {
        return (
          passesFilters(room.vacancyStatus, Object.values(VACANCY_STATUS)) &&
          passesFilters(room.cleanStatus, Object.values(CLEAN_STATUS)) &&
          (!filters[DO_NOT_DISTURB_STATUS] || room.doNotDisturb)
        );
      }),
    };
  }, [store.roomsWithTaskProperties, filters]);

  const firstSelectedFilterIsCleanStatus =
    Object.values(CLEAN_STATUS).some(
      (cleanStatus) => firstSelectedFilter === cleanStatus
    ) || DO_NOT_DISTURB_STATUS === firstSelectedFilter;
  const firstSelectedFilterIsVacancyStatus = Object.values(VACANCY_STATUS).some(
    (vacancyStatus) => firstSelectedFilter === vacancyStatus
  );

  const dynamicFilterCounts = {
    [VACANCY_STATUS.BLOCKED]: store.roomStore.blockedRoomCount,
    [VACANCY_STATUS.CHECKOUT]: store.roomStore.checkoutRoomCount,
    [VACANCY_STATUS.STAYOVER]: store.roomStore.stayoverRoomCount,
    [VACANCY_STATUS.VACANT]: store.roomStore.vacantRoomCount,
    [CLEAN_STATUS.CLEAN]: store.roomStore.cleanRoomCount,
    [CLEAN_STATUS.DIRTY]: store.roomStore.dirtyRoomCount,
    [DO_NOT_DISTURB_STATUS]: store.roomStore.doNotDisturbRoomCount,
  };

  if (firstSelectedFilterIsCleanStatus) {
    dynamicFilterCounts[VACANCY_STATUS.BLOCKED] = 0;
    dynamicFilterCounts[VACANCY_STATUS.CHECKOUT] = 0;
    dynamicFilterCounts[VACANCY_STATUS.STAYOVER] = 0;
    dynamicFilterCounts[VACANCY_STATUS.VACANT] = 0;
    rooms.forEach((room) => {
      if (
        passesFilters(room.cleanStatus, Object.values(CLEAN_STATUS)) &&
        (!filters[DO_NOT_DISTURB_STATUS] || room.doNotDisturb)
      ) {
        dynamicFilterCounts[room.vacancyStatus]++;
      }
    });
  } else if (firstSelectedFilterIsVacancyStatus) {
    dynamicFilterCounts[CLEAN_STATUS.CLEAN] = 0;
    dynamicFilterCounts[CLEAN_STATUS.DIRTY] = 0;
    dynamicFilterCounts[DO_NOT_DISTURB_STATUS] = 0;
    rooms.forEach((room) => {
      if (passesFilters(room.vacancyStatus, Object.values(VACANCY_STATUS))) {
        dynamicFilterCounts[room.cleanStatus]++;
        if (room.doNotDisturb) dynamicFilterCounts[DO_NOT_DISTURB_STATUS]++;
      }
    });
  }

  const { isAnyFetchLoading } = store;

  return (
    <RoomFiltersContext.Provider
      value={{
        filters,
        clearFilters,
        toggleFilter,
        isFilterDisabled,
        dynamicFilterCounts,
      }}
    >
      {isSmallScreen ? (
        <RoomFiltersMobile />
      ) : (
        <RoomFilters setIsDrawerOpen={setIsDrawerOpen} />
      )}
      <RoomsList
        networkError={networkError}
        rooms={rooms}
        generalAreas={generalAreas}
        isAnyFetchLoading={isAnyFetchLoading}
      />
    </RoomFiltersContext.Provider>
  );
});

export default RoomScreen;
