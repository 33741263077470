import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import UploadIcon from '@material-ui/icons/UploadFile';
import {
  IconButton,
  Tooltip,
  Toolbar,
  AppBar,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { request, uploadSPHPDF } from 'src/utils/LodgebookAPIClient';
import { useStore } from 'src/context/StoreContext';
import PrintRoomAssignments from 'src/screens/Dashboard/PrintRoomAssignments/PrintRoomAssignments';
import { ReactComponent as LodgebookLogo } from 'src/assets/images/lodgebook_logo.svg';
import useBreakpoints from 'src/utils/useBreakpoints';
import { useConfirmationModal } from 'src/components/ConfirmationModal';
import NavigationBarMenu from './components/NavigationBarMenu';
import { TRANSLATION_KEYS } from 'src/constants/translations';
import { Recommend } from '@material-ui/icons';

const Header = styled(AppBar)`
  height: 65px;
  z-index: 0;
  box-shadow: none;
`;

const UserInitials = styled(Avatar)`
  background-color: #666666;
  color: #fff;
  cursor: pointer;
`;

const NavBarEndContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  max-width: 500px;
`;

const TopNav = styled(Toolbar)`
  justify-content: space-between;
`;

const HotelInfo = styled(Typography)`
  color: #666666;
  font-weight: bold;
`;

export const UPLOAD_SYNXIS_PDF_TEXT = 'UPLOAD SYNXIS PDF DATA';

const NavigationBar = observer(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { userStore, hotelStore, roomStore, taskStore } = useStore();
  const { fetchAllRooms } = roomStore;
  const {
    selectedHotelId,
    name,
    lastSyncAt,
    fetchAndSetHotel,
    didUploadPDFToday,
  } = hotelStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const [roomAssignments, setRoomAssignments] = useState(null);
  const { isSmallScreen } = useBreakpoints();
  const fileInputRef = useRef(null);
  const { showConfirmationModal } = useConfirmationModal();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingComplete, setIsUploadingComplete] = useState(false);

  useEffect(() => {
    if (selectedHotelId) {
      (async function fetchRoomAssignments() {
        const roomAssignmentsRequest = await request(
          `/room_assignments?hotel_id=${selectedHotelId}`,
          'GET'
        );
        setRoomAssignments(roomAssignmentsRequest.roomAssignments);
      })();
    }
  }, [selectedHotelId]);

  let userInitials = '?';
  const { authenticatedUser } = userStore;
  if (authenticatedUser) {
    userInitials = `${authenticatedUser.firstName.charAt(
      0
    )}${authenticatedUser.lastName.charAt(0)}`;
  }

  const uploadPdf = async ({ target }) => {
    setIsUploadingComplete(false);
    setIsUploading(true);
    try {
      await fetchAndSetHotel();
    } catch (e) {}

    const runAndShowErrorIfThrown = () => {
      showConfirmationModal({
        variant: 'error-only',
        errorTitle: t(
          TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_TITLE
        ),
        errorPrompt: t(
          TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_PROMPT
        ),
        onConfirm: async () => {
          await uploadSPHPDF(target.files[0], selectedHotelId);
        },
        onSuccess: async () => {
          setIsUploadingComplete(true);
          await fetchAndSetHotel();
          fetchAllRooms(selectedHotelId);
        },
        onClose: () => {
          fileInputRef.current.value = null;
          setIsUploading(false);
        },
      });
    };

    if (didUploadPDFToday) {
      showConfirmationModal({
        variant: 'confirm-only',
        title: t(
          TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_TITLE
        ),
        prompt: t(
          TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_PROMPT
        ),
        onConfirm: runAndShowErrorIfThrown,
        onCancel: () => {
          fileInputRef.current.value = null;
          setIsUploading(false);
        },
      });
    } else {
      runAndShowErrorIfThrown();
    }
  };

  const getUploadMessage = () => {
    if (isUploading) {
      return <Typography variant="body1">Uploading</Typography>;
    }
    if (isUploadingComplete) {
      setTimeout(() => {
        setIsUploadingComplete(false);
      }, 5000);
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Recommend width={12} style={{ marginRight: 6 }} />
          <Typography variant="body1">Upload complete!</Typography>
        </div>
      );
    }

    if (lastSyncAt) {
      return (
        <Typography variant="body1" color={theme.palette.grey.main}>{`${t(
          TRANSLATION_KEYS.LAST_UPDATED
        )} ${new Intl.DateTimeFormat('en-US', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }).format(new Date(lastSyncAt))}`}</Typography>
      );
    }

    return null;
  };

  return (
    <>
      <Header color="secondary">
        <TopNav>
          {isSmallScreen ? (
            <HotelInfo variant="h6">{hotelStore.name}</HotelInfo>
          ) : (
            <LodgebookLogo />
          )}
          <NavBarEndContainer>
            {getUploadMessage()}
            <Tooltip title={UPLOAD_SYNXIS_PDF_TEXT}>
              <IconButton
                color="primary"
                aria-label={UPLOAD_SYNXIS_PDF_TEXT}
                component="label"
                style={{ paddingRight: 0 }}
              >
                <UploadIcon />
                <input
                  ref={fileInputRef}
                  data-testid={UPLOAD_SYNXIS_PDF_TEXT}
                  accept="file/*"
                  id="contained-button-file"
                  multiple
                  onChange={uploadPdf}
                  type="file"
                  hidden
                />
              </IconButton>
            </Tooltip>

            <PrintRoomAssignments
              userStore={userStore}
              hotelStore={hotelStore}
              taskStore={taskStore}
              roomStore={roomStore}
              roomAssignments={roomAssignments}
            />

            <UserInitials
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Typography variant="caption">{userInitials}</Typography>
            </UserInitials>
          </NavBarEndContainer>
        </TopNav>
      </Header>
      <NavigationBarMenu
        menuVisible={menuVisible}
        handleClickAway={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
      />
    </>
  );
});

NavigationBar.propTypes = {};

export default NavigationBar;
