export const TRANSLATION_KEYS = {
  YES: 'YES',
  NO: 'NO',
  OKAY: 'OKAY',
  CANCEL: 'CANCEL',
  RETRY: 'RETRY',
  CONFIRMATION_MODAL_EARLY_CHECKOUT_TITLE:
    'CONFIRMATION_MODAL_EARLY_CHECKOUT_TITLE',
  CONFIRMATION_MODAL_EARLY_CHECKOUT_PROMPT:
    'CONFIRMATION_MODAL_EARLY_CHECKOUT_PROMPT',
  CONFIRMATION_MODAL_EARLY_CHECKOUT_SUCCESS_PROMPT:
    'CONFIRMATION_MODAL_EARLY_CHECKOUT_SUCCESS_PROMPT',
  CONFIRMATION_MODAL_VACANT_TITLE: 'CONFIRMATION_MODAL_VACANT_TITLE',
  CONFIRMATION_MODAL_VACANT_PROMPT: 'CONFIRMATION_MODAL_VACANT_PROMPT',
  CONFIRMATION_MODAL_VACANT_SUCCESS_PROMPT:
    'CONFIRMATION_MODAL_VACANT_SUCCESS_PROMPT',
  CONFIRMATION_MODAL_EXTENDED_STAY_TITLE:
    'CONFIRMATION_MODAL_EXTENDED_STAY_TITLE',
  CONFIRMATION_MODAL_EXTENDED_STAY_PROMPT:
    'CONFIRMATION_MODAL_EXTENDED_STAY_PROMPT',
  CONFIRMATION_MODAL_EXTENDED_STAY_SUCCESS_PROMPT:
    'CONFIRMATION_MODAL_EXTENDED_STAY_SUCCESS_PROMPT',
  CONFIRMATION_MODAL_ERROR_TITLE: 'CONFIRMATION_MODAL_ERROR_TITLE',
  CONFIRMATION_MODAL_ERROR_PROMPT: 'CONFIRMATION_MODAL_ERROR_PROMPT',
  CONFIRMATION_MODAL_PDF_UPLOAD_TITLE: 'CONFIRMATION_MODAL_PDF_UPLOAD_TITLE',
  CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_TITLE:
    'CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_TITLE',
  CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_PROMPT:
    'CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_PROMPT',
  CONFIRMATION_MODAL_PDF_UPLOAD_PROMPT: 'CONFIRMATION_MODAL_PDF_UPLOAD_PROMPT',
  CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_TITLE:
    'CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_TITLE',
  CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_PROMPT:
    'CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_PROMPT',
  LAST_UPDATED: 'LAST_UPDATED',
  LOADING: 'LOADING',
  UPLOADING: 'UPLOADING',
} as const;

const reusableTranslations = {
  [TRANSLATION_KEYS.YES]: {
    en: 'Yes',
    es: 'Sí',
    zh: '要',
  },
  [TRANSLATION_KEYS.NO]: {
    en: 'No',
    es: 'No',
    zh: '不要',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_TITLE]: {
    en: 'You are about to mark this\nroom as Checkout.',
    es: 'Estás a punto de marcar esta\nhabitación como salida.',
    zh: '您即将标记此\n房间为退房。',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_PROMPT]: {
    en: 'Is Room {{roomNumber}}\nchecking out early?',
    es: '¿La habitación {{roomNumber}}\nestá realizando salida anticipada?',
    zh: '房间 {{roomNumber}}\n是否提前退房？',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_SUCCESS_PROMPT]: {
    en: 'Room {{roomNumber}} has been\nchanged to Checkout.',
    es: 'La habitación {{roomNumber}} ha sido\ncambiada a Salida.',
    zh: '房间 {{roomNumber}} 已\n更改为退房。',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_TITLE]: {
    en: 'You are about to mark this\nroom as Vacant.',
    es: 'Estás a punto de marcar esta\nhabitación como Vacante.',
    zh: '您即将把这个房间\n标记为空闲。',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_PROMPT]: {
    en: 'Is Room {{roomNumber}} empty?',
    es: '¿Está vacante la habitación {{roomNumber}}?',
    zh: '房间 {{roomNumber}}空吗？',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_SUCCESS_PROMPT]: {
    en: 'Room {{roomNumber}} has been\nchanged to Vacant.',
    es: 'La habitación {{roomNumber}} ha sido\ncambiada a Vacante.',
    zh: '房间 {{roomNumber}} 已被\n更改为空闲。`',
  },

  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_TITLE]: {
    en: 'You are about to mark this\nroom as Stayover.',
    es: 'Estás a punto de marcar esta\nhabitación como estancia prolongada.',
    zh: '您即将将此房间\n标记为延长住宿。',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_PROMPT]: {
    en: 'Is Room {{roomNumber}}\nextending their stay?',
    es: '¿La habitación {{roomNumber}}\nestá extendiendo su estancia?',
    zh: '房间{{roomNumber}}\n是否在延长住宿？',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_SUCCESS_PROMPT]: {
    en: 'Room {{roomNumber}} has been\nchanged to Stayover.',
    es: 'La habitación {{roomNumber}}\nse ha cambiado a estancia prolongada.',
    zh: '房间{{roomNumber}}\n已更改为延长住宿。',
  },
  [TRANSLATION_KEYS.OKAY]: {
    en: 'Okay',
    es: 'Está bien',
    zh: '好的',
  },
  [TRANSLATION_KEYS.CANCEL]: {
    en: 'Cancel',
    es: 'Cancelar',
    zh: '取消',
  },
  [TRANSLATION_KEYS.RETRY]: {
    en: 'Retry',
    es: 'Reintentar',
    zh: '重试',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_ERROR_TITLE]: {
    en: 'Error: Action Failed',
    es: 'Error: Acción fallida',
    zh: '错误：操作失败',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_ERROR_PROMPT]: {
    en: `Something went wrong. Please try again or reach out to <a href='mailto:{{email}}'>{{email}}</a> for support.`,
    es: `Algo salió mal. Por favor, intente de nuevo o póngase en contacto con <a href='mailto:{{email}}'>{{email}}</a> para soporte.`,
    zh: `出了点问题。请重试或通过 <a href='mailto:{{email}}'>{{email}}</a> 联系我们寻求支持。`,
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_TITLE]: {
    en: 'Upload Room List',
    es: 'Subir lista de habitaciones',
    zh: '上传房间列表',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_PROMPT]: {
    en: 'Are you sure you want to update the Rooms?',
    es: '¿Estás seguro de que quieres actualizar las habitaciones?',
    zh: '您确定要更新房间吗？',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_TITLE]: {
    en: `You have already updated room statuses for today.`,
    es: `Ya has actualizado los estados de las habitaciones para hoy.`,
    zh: `您今天已经更新了房间状态。`,
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_SECONDARY_UPLOAD_PROMPT]: {
    en: `Are you sure you want to upload again?`,
    es: `¿Estás seguro de que quieres subirlo nuevamente?`,
    zh: `您确定要再次上传吗？`,
  },

  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_TITLE]: {
    en: 'File upload failed',
    es: 'Error al subir el archivo',
    zh: '文件上传失败',
  },
  [TRANSLATION_KEYS.CONFIRMATION_MODAL_PDF_UPLOAD_ERROR_PROMPT]: {
    en:
      'Please make sure you are only uploading the Rooms List PDF downloaded from SynXis.',
    es:
      'Por favor, asegúrate de que solo estás subiendo el PDF de la lista de habitaciones descargado desde SynXis.',
    zh: '请确保您仅上传从 SynXis 下载的房间列表 PDF 文件。',
  },
  [TRANSLATION_KEYS.LAST_UPDATED]: {
    en: 'Last updated',
    es: 'Última actualización',
    zh: '最后更新',
  },
  [TRANSLATION_KEYS.LOADING]: {
    en: 'Loading',
    es: 'Cargando',
    zh: '加载中',
  },
  [TRANSLATION_KEYS.UPLOADING]: {
    en: 'Uploading',
    es: 'Subiendo',
    zh: '上传中',
  },
};

export default {
  ...reusableTranslations,
};
