import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import { COMPLETE_STATUS } from 'src/models/TaskStore';

export const CREATED_TEXT = 'Created';
export const ASSIGNED_TEXT = 'Assigned';
export const COMPLETED_TEXT = 'Completed';
export const AGO_TEXT = 'ago';

const TextContainer = styled('div')`
  color: #666666;
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
`;

const Text = styled(Typography)`
  color: #666666;
`;
Text.defaultProps = {
  variant: 'caption',
};

export const getRelativeTimestamp = (date) => {
  return `${moment.duration(moment() - moment(date)).humanize()} ago`;
};

const TaskDetailsCardText = ({ task }) => {
  const { createdByUser, completedByUser } = task;
  return (
    <TextContainer>
      {createdByUser ? (
        <Text>
          {' '}
          {`Created by ${createdByUser.firstName} ${getRelativeTimestamp(
            task.createdAt
          )}`}{' '}
        </Text>
      ) : (
        <Text>{`Created ${getRelativeTimestamp(task.createdAt)}`}</Text>
      )}

      {task.taskAssignments.length > 0 ? (
        <>
          <Text> &nbsp; | &nbsp; </Text>
          <Text>{`${ASSIGNED_TEXT} ${getRelativeTimestamp(
            task.assignedAt
          )}`}</Text>
        </>
      ) : null}
      {task.status === COMPLETE_STATUS ? (
        <>
          <Text> &nbsp; | &nbsp; </Text>
          {completedByUser ? (
            <Text>{`${COMPLETED_TEXT} by ${
              completedByUser.firstName
            } ${getRelativeTimestamp(task.completedAt)}`}</Text>
          ) : (
            <Text>{`${COMPLETED_TEXT} ${getRelativeTimestamp(
              task.completedAt
            )}`}</Text>
          )}
        </>
      ) : null}
    </TextContainer>
  );
};

TaskDetailsCardText.propTypes = {
  task: PropTypes.object,
  createdByName: PropTypes.string,
};

export default TaskDetailsCardText;
