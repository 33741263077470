const colors = {
  light: {
    green: {
      main: '#20b2aa',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    grey: {
      main: '#666666',
      light: '#8E8E8E',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    red: {
      main: '#eb4d5f',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    pink: {
      main: '#f6a4b2',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    orange: {
      main: '#f9a148',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    blue: {
      main: '#95e6e4',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    black: {
      main: '#000000',
      contrastText: 'rgba(255,255,255,0.87)',
    },
  },
};

const theme = {
  palette: {
    type: 'light',
    primary: colors.light.green,
    secondary: {
      main: '#ffffff',
      contrastText: 'rgba(32,178,170,0.87)',
    },
    background: {
      default: '#ffffff',
    },
    error: colors.light.red,
    warning: colors.light.orange,
    info: colors.light.blue,
    success: colors.light.green,
    ...colors.light,
  },
  typography: {
    fontFamily: 'Open Sans',
    button: {
      fontWeight: 700,
    },
  },
};

const overrides = {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(32, 178, 170, 0.1)',
          },
        },
      },
    },
    MuiBadge: {
      defaultProps: {
        color: 'red',
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: colors.light.grey,
          '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  },
};

export default {
  ...theme,
  ...overrides,
};
