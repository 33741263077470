import { types, getSnapshot, applySnapshot, flow } from 'mobx-state-tree';

import RoomStore from './RoomStore';
import HotelStore from './HotelStore';
import TaskStore, { INCOMPLETE_STATUS } from './TaskStore';
import UserStore from './UserStore';
import HotelTaskStore from './HotelTaskStore';
import NotificationStore from './NotificationStore';
import TextMessageStore from './TextMessageStore';

const Store = types
  .model('Store', {
    roomStore: types.optional(RoomStore, {}),
    taskStore: types.optional(TaskStore, {}),
    userStore: types.optional(UserStore, {}),
    hotelStore: types.optional(HotelStore, {}),
    hotelTaskStore: types.optional(HotelTaskStore, {}),
    notificationStore: types.optional(NotificationStore, {}),
    textMessageStore: types.optional(TextMessageStore, {}),
  })
  .views((self) => ({
    get isAnyFetchLoading() {
      return (
        self.taskStore.isFetchingAll ||
        self.roomStore.isFetchingAll ||
        self.hotelTaskStore.isFetchingAll ||
        self.userStore.isFetchingAll ||
        self.textMessageStore.isFetchingAll
      );
    },
    get networkErrors() {
      return [
        self.taskStore.networkError,
        self.hotelTaskStore.networkError,
        self.roomStore.networkError,
        self.userStore.networkError,
        self.textMessageStore.networkError,
      ].filter((e) => e && e !== '');
    },
    get roomsWithTaskProperties() {
      return self.roomStore.roomsInNumericalOrder.map((room) => {
        const roomTasks = self.taskStore.tasksForRoomId(room.id);
        const incompleteTasks = roomTasks.filter(
          (task) => task.status === INCOMPLETE_STATUS
        );
        room.incompleteTaskCount = incompleteTasks.length;
        room.hasTask = incompleteTasks.length > 0;
        getSnapshot(room);
        return room;
      });
    },
  }))
  .actions((self) => ({
    dismissErrors() {
      self.taskStore.dismissNetworkError();
      self.roomStore.dismissNetworkError();
      self.userStore.dismissNetworkError();
      self.hotelTaskStore.dismissNetworkError();
      self.textMessageStore.dismissNetworkError();
    },
    fetchAll: flow(function*() {
      yield self.hotelStore.fetchAndSetHotel();

      self.taskStore.fetchAllTasks(self.hotelStore.selectedHotelId);
      self.roomStore.fetchAllRooms(self.hotelStore.selectedHotelId);
      self.userStore.fetchAllUsers(self.hotelStore.selectedHotelId);
      self.hotelTaskStore.fetchAllHotelTasks(self.hotelStore.selectedHotelId);
      self.textMessageStore.fetchAllTextMessages(
        self.hotelStore.selectedHotelId
      );
    }),
    resetStore() {
      const whiteList = {};
      applySnapshot(self, whiteList);
    },
  }));

export default Store;
