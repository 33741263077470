import { types, flow } from 'mobx-state-tree';

import { request } from '../utils/LodgebookAPIClient';
import moment from 'moment';

export const HOTELS_URL = '/hotels';

const HotelStore = types
  .model('HotelStore', {
    selectedHotelId: types.maybe(types.number),
    isFetchingHotels: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
    timeZone: types.maybe(types.string),
    name: types.maybe(types.string),
    phoneNumber: types.maybeNull(types.string),
    lastSyncAt: types.maybeNull(types.string),
  })
  .views((self) => ({
    get didUploadPDFToday() {
      if (self.lastSyncAt) {
        return moment
          .utc(self.lastSyncAt)
          .tz(self.timeZone)
          .isSame(moment().tz(self.timeZone), 'day');
      }

      return false;
    },
  }))
  .actions((self) => ({
    fetchAndSetHotel: flow(function*() {
      self.networkError = undefined;
      self.isFetchingHotels = true;
      try {
        const { hotels } = yield request(HOTELS_URL, 'GET');
        let hotel = hotels[0];
        if (self.selectedHotelId !== undefined) {
          hotel = hotels.find((hotel) => hotel.id === self.selectedHotelId);
        }

        self.selectedHotelId = hotel.id;
        self.timeZone = hotel?.timeZone;
        self.name = hotel.name;
        self.phoneNumber = hotel?.phoneNumber;
        self.lastSyncAt = hotel?.lastSyncAt;
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch hotel', error);
      }
      self.isFetchingHotels = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default HotelStore;
