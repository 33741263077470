import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  Modal,
  Typography,
  Button,
  CircularProgress,
  TypographyProps,
  ButtonProps,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

import { TRANSLATION_KEYS } from 'src/constants/translations';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';

export interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  titleProps?: TypographyProps;
  titleContainerStyle?: React.CSSProperties;
  prompt: string;
  promptProps?: TypographyProps;
  promptContainerStyle?: React.CSSProperties;
  loadingTitle: string;
  loadingTitleProps?: TypographyProps;
  loadingTitleContainerStyle?: React.CSSProperties;
  loadingPrompt: string;
  loadingPromptProps?: TypographyProps;
  loadingPromptContainerStyle?: React.CSSProperties;
  successPrompt: string;
  successPromptProps?: TypographyProps;
  successPromptContainerStyle?: React.CSSProperties;
  errorTitle: string;
  errorTitleProps?: TypographyProps;
  errorTitleContainerStyle?: React.CSSProperties;
  errorPrompt: string;
  errorPromptProps?: TypographyProps;
  errorPromptContainerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onCancel: () => void;
  cancelButtonText?: string;
  cancelButtonProps?: ButtonProps;
  onConfirm: () => Promise<void>;
  confirmButtonText?: string;
  confirmButtonProps?: ButtonProps;
  closeModal: () => void;
  /**
   * Callback that happens whenever the modal is closed (i.e. at the very end, no matter what state the modal is in)
   */
  onClose: () => void;
  /**
   * Callback that happens immediately after onConfirm succeeds
   * Example use case: fetching all of the rooms after uploading a pdf.
   */
  onSuccess: () => void;
  onSuccessModalClose: () => void;
  successButtonText?: string;
  successButtonProps?: ButtonProps;
  buttonContainerStyle?: React.CSSProperties;
  /**
   * confirm-only: No loading, error or success states
   * loading-error-success-only: Basically skips the confirmation step
   * loading-error-only: no confirmation or success state
   * confirm-loading-error-only: no success message at the end
   */
  variant?:
    | 'default'
    | 'confirm-only'
    | 'loading-error-success-only'
    | 'loading-error-only'
    | 'confirm-loading-error-only'
    | 'error-only';
  shouldAutoCloseAfterSuccess?: boolean;
  /**
   * How long to keep the modal open after it finishes loading
   * Milliseconds
   */
  autoCloseTimeout?: number;
}

const ICON_SIZE = 43;

export const TEST_IDS = {
  CONFIRMATION_STATE: 'CONFIRMATION_STATE',
  LOADING_STATE: 'LOADING_STATE',
  SUCCESS_STATE: 'SUCCESS_STATE',
  ERROR_STATE: 'ERROR_STATE',
};

const ConfirmationModal = ({
  isOpen = false,
  title,
  titleProps,
  titleContainerStyle,
  prompt,
  promptProps,
  promptContainerStyle,
  loadingTitle,
  loadingTitleProps,
  loadingTitleContainerStyle,
  loadingPrompt,
  loadingPromptProps,
  loadingPromptContainerStyle,
  successPrompt,
  successPromptProps,
  successPromptContainerStyle,
  errorTitle,
  errorTitleProps,
  errorTitleContainerStyle,
  errorPrompt,
  errorPromptProps,
  errorPromptContainerStyle,
  containerStyle,
  onCancel = () => {},
  cancelButtonText,
  cancelButtonProps,
  onConfirm = async () => {},
  confirmButtonText,
  confirmButtonProps,
  closeModal = () => {},
  successButtonText,
  successButtonProps,
  buttonContainerStyle,
  onSuccess = () => {},
  onSuccessModalClose = () => {},
  shouldAutoCloseAfterSuccess = true,
  autoCloseTimeout = 3000,
  variant = 'default',
}: ConfirmationModalProps) => {
  const skipConfirmation = useMemo(
    () =>
      variant === 'loading-error-only' ||
      variant === 'loading-error-success-only' ||
      variant === 'error-only',
    [variant]
  );
  const theme = useTheme();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(skipConfirmation);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);

  const { style: titleStyle, ...restTitleProps } = titleProps || {};
  const { style: promptStyle, ...restPromptProps } = promptProps || {};
  const { style: loadingTitleStyle, ...restloadingTitleProps } =
    loadingTitleProps || {};
  const { style: loadingPromptStyle, ...restloadingPromptProps } =
    loadingPromptProps || {};
  const { style: successPromptStyle, ...restSuccessPromptProps } =
    successPromptProps || {};
  const { style: errorTitleStyle, ...restErrorTitleProps } =
    errorTitleProps || {};
  const { style: errorPromptStyle, ...restErrorPromptProps } =
    errorPromptProps || {};
  const { style: cancelButtonStyle, ...restCancelButtonProps } =
    cancelButtonProps || {};
  const { style: confirmButtonStyle, ...restConfirmButtonProps } =
    confirmButtonProps || {};
  const { style: successButtonStyle, ...restSuccessButtonProps } =
    successButtonProps || {};

  const resetState = () => {
    setIsLoading(skipConfirmation);
    setIsSuccess(false);
    setError(null);
  };

  const timerRef = useRef(null);
  const successButtonHandler = () => {
    onSuccessModalClose();
    closeModal();
  };
  useEffect(() => {
    if (isSuccess && shouldAutoCloseAfterSuccess) {
      timerRef.current = setTimeout(() => {
        successButtonHandler();
      }, autoCloseTimeout);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isSuccess]);

  const modifiedOnConfirm = async () => {
    switch (variant) {
      case 'confirm-only':
        closeModal();
        setTimeout(() => {
          onConfirm();
        }, 50);
        break;
      case 'loading-error-only':
      case 'confirm-loading-error-only':
        setIsLoading(true);
        try {
          await onConfirm();
          onSuccess();
          closeModal();
        } catch (e) {
          setError(e);
        }
        setIsLoading(false);
        break;

      case 'error-only':
        try {
          setIsLoading(false);
          await onConfirm();
          onSuccess();
          closeModal();
        } catch (e) {
          setError(e);
        }
        break;
      default:
        setIsLoading(true);
        try {
          await onConfirm();
          onSuccess();
          setIsSuccess(true);
        } catch (e) {
          setError(e);
        }

        setIsLoading(false);
        break;
    }
  };

  useEffect(() => {
    if (skipConfirmation) {
      modifiedOnConfirm();
    }
  }, [skipConfirmation]);

  const ConfirmationState = () => {
    return (
      <Container
        data-testid={TEST_IDS.CONFIRMATION_STATE}
        style={containerStyle}
      >
        <TitleContainer style={titleContainerStyle}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              ...titleStyle,
            }}
            variant="subtitle1"
            align="center"
            {...restTitleProps}
          >
            {title?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </TitleContainer>

        <PromptContainer style={promptContainerStyle}>
          <Typography
            sx={{
              color: theme.palette.grey.main,
              fontWeight: 'bold',
              ...promptStyle,
            }}
            variant="h5"
            align="center"
            {...restPromptProps}
          >
            {prompt?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </PromptContainer>

        <ButtonsContainer style={buttonContainerStyle}>
          <Button
            style={{ width: '40%', ...cancelButtonStyle }}
            color="grey"
            disabled={isLoading}
            onClick={onCancel}
            {...restCancelButtonProps}
          >
            {cancelButtonText ?? t(TRANSLATION_KEYS.NO)}
          </Button>
          <Button
            style={{ width: '40%', ...confirmButtonStyle }}
            variant="contained"
            disabled={isLoading}
            onClick={modifiedOnConfirm}
            {...restConfirmButtonProps}
          >
            {confirmButtonText ?? t(TRANSLATION_KEYS.YES)}
          </Button>
        </ButtonsContainer>
      </Container>
    );
  };

  const LoadingState = () => {
    return (
      <Container data-testid={TEST_IDS.LOADING_STATE} style={containerStyle}>
        <TitleContainer
          style={
            loadingTitle && (loadingTitleContainerStyle ?? titleContainerStyle)
          }
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              ...(loadingTitle
                ? loadingTitleStyle ?? titleContainerStyle
                : titleContainerStyle),
            }}
            variant="subtitle1"
            align="center"
            {...(loadingTitle
              ? restloadingTitleProps ?? restTitleProps
              : restTitleProps)}
          >
            {(loadingTitle ?? title)?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </TitleContainer>

        <PromptContainer
          style={loadingPromptContainerStyle ?? promptContainerStyle}
        >
          <Typography
            sx={{
              color: theme.palette.grey.main,
              fontWeight: 'bold',
              ...(loadingPromptStyle ?? promptStyle),
            }}
            variant="h5"
            align="center"
            {...(restloadingPromptProps ?? restPromptProps)}
          >
            {(loadingPrompt ?? t(TRANSLATION_KEYS.LOADING))
              ?.split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </Typography>
        </PromptContainer>

        <ButtonsContainer style={buttonContainerStyle}>
          <ButtonsContainer>
            <CircularProgress size={24} color={'grey'} />
          </ButtonsContainer>
        </ButtonsContainer>
      </Container>
    );
  };
  const SuccessState = () => {
    return (
      <Container data-testid={TEST_IDS.SUCCESS_STATE} style={containerStyle}>
        <SuccessTitleContainer style={titleContainerStyle}>
          <SuccessImage />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              ...titleStyle,
            }}
            variant="subtitle1"
            align="center"
          >
            Success!
          </Typography>
        </SuccessTitleContainer>

        <PromptContainer style={successPromptContainerStyle}>
          <Typography
            sx={{
              color: theme.palette.grey.main,
              fontWeight: 'bold',
              ...successPromptStyle,
            }}
            variant="h5"
            align="center"
            {...restSuccessPromptProps}
          >
            {successPrompt?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </PromptContainer>

        <ButtonsContainer style={buttonContainerStyle}>
          <Button
            style={{ width: '40%', ...successButtonStyle }}
            variant="contained"
            onClick={() => {
              resetState();
              successButtonHandler();
            }}
            {...restSuccessButtonProps}
          >
            {successButtonText ?? t(TRANSLATION_KEYS.OKAY)}
          </Button>
        </ButtonsContainer>
      </Container>
    );
  };
  const ErrorState = () => {
    return (
      <ErrorContainer data-testid={TEST_IDS.ERROR_STATE}>
        <div
          style={{
            width: '100%',
            height: 35,
            backgroundColor: theme.palette.red.main,
            borderRadius: 5,
          }}
        />

        <div
          style={{
            padding: 24,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ErrorImage />
          <div style={{ marginLeft: 24 }}>
            <TitleContainer style={errorPromptContainerStyle}>
              <Typography
                sx={{
                  color: theme.palette.red.main,
                  fontWeight: 'bold',
                  ...errorTitleStyle,
                }}
                variant="h5"
                {...restErrorTitleProps}
              >
                {errorTitle ??
                  t(TRANSLATION_KEYS.CONFIRMATION_MODAL_ERROR_TITLE)}
              </Typography>
            </TitleContainer>

            <div
              style={{
                ...errorPromptContainerStyle,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.grey.main,
                  ...errorPromptStyle,
                }}
                variant="body1"
                align="left"
                {...restErrorPromptProps}
              >
                {errorPrompt ? (
                  errorPrompt?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <Trans
                    i18nKey={TRANSLATION_KEYS.CONFIRMATION_MODAL_ERROR_PROMPT}
                    values={{ email: 'mike@mylodgebook.com' }}
                    components={{ a: <a /> }}
                  />
                )}
              </Typography>
            </div>

            <ErrorButtonsContainer style={buttonContainerStyle}>
              <Button
                style={{ width: '25%' }}
                color="grey"
                disabled={isLoading}
                onClick={() => {
                  resetState();
                  onCancel();
                }}
                // {...restCancelButtonProps}
              >
                {t(TRANSLATION_KEYS.CANCEL)}
              </Button>
              <Button
                style={{ width: '25%' }}
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.grey.light,
                  '&:hover': {
                    backgroundColor: theme.palette.grey.main,
                  },
                }}
                disabled={isLoading}
                onClick={() => {
                  setError(null);
                  modifiedOnConfirm();
                }}
                // {...restConfirmButtonProps}
              >
                {t(TRANSLATION_KEYS.RETRY)}
              </Button>
            </ErrorButtonsContainer>
          </div>
        </div>
      </ErrorContainer>
    );
  };

  const ToRender = () => {
    if (error !== null) {
      return <ErrorState />;
    }

    if (isLoading) {
      return <LoadingState />;
    }

    if (isSuccess) {
      return <SuccessState />;
    }

    return <ConfirmationState />;
  };

  return (
    <StyledModal open={variant === 'error-only' ? error : isOpen}>
      <ToRender />
    </StyledModal>
  );
};

const useConfirmationModalManager = (): {
  isConfirmationModalOpen: boolean;
  confirmationModalProps: ConfirmationModalProps;
  showConfirmationModal: (props: ConfirmationModalProps) => void;
} => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<
    ConfirmationModalProps
  >({});

  const showConfirmationModal = ({
    isOpen,
    onCancel = () => {},
    onConfirm = async () => {},
    onSuccessModalClose = () => {},
    closeModal: notUsedCloseModal,
    onClose = () => {},
    ...props
  }: ConfirmationModalProps) => {
    setIsConfirmationModalOpen(true);

    /**
     * We need to reset the modal and its props when it closes since the props for are stateful
     */
    const modifiedOnClose = () => {
      setConfirmationModalProps({});
      onClose();
    };

    const closeModal = () => {
      setIsConfirmationModalOpen(false);
      modifiedOnClose();
    };

    setConfirmationModalProps({
      onCancel: () => {
        setIsConfirmationModalOpen(false);
        modifiedOnClose();
        onCancel();
      },
      onConfirm: async () => {
        await onConfirm();
      },
      closeModal,
      onSuccessModalClose,
      ...props,
    } as ConfirmationModalProps);
  };

  return {
    isConfirmationModalOpen,
    confirmationModalProps,
    showConfirmationModal,
  };
};

export const ConfirmationModalContext = createContext<{
  showConfirmationModal: (props: ConfirmationModalProps) => void;
}>({
  showConfirmationModal: () => {},
});

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (!context) {
    throw new Error(
      'useConfirmationModal must be used within a ConfirmationModalProvider'
    );
  }
  return context;
};

export const useManagedConfirmationModal = () => {
  const {
    isConfirmationModalOpen,
    confirmationModalProps,
    showConfirmationModal,
  } = useConfirmationModalManager();

  const ConfirmationModalToRender = useCallback(() => {
    return (
      <>
        {isConfirmationModalOpen && (
          <ConfirmationModal {...confirmationModalProps} isOpen />
        )}
      </>
    );
  }, [isConfirmationModalOpen, confirmationModalProps]);

  return {
    showConfirmationModal,
    ConfirmationModal: ConfirmationModalToRender,
  };
};

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 9999;
`;

const Container = styled('div')`
  width: 368px;
  outline: 0;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
`;

const ErrorContainer = styled('div')`
  width: 576px;
  outline: 0;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
`;

const TitleContainer = styled('div')`
  margin-left: 6px;
  margin-right: 6px;
`;
const SuccessTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PromptContainer = styled('div')`
  padding-top: 24px;
  padding-bottom: 30px;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 37px;
`;

const ErrorButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 37px;
`;

const ErrorImage = styled(ErrorIcon)`
  height: 60px;
  width: 60px;
  fill: ${({ theme }) => theme.palette.red.main};
`;

const SuccessImage = styled(SuccessIcon)`
  height: 43px;
  width: 43px;
  fill: ${({ theme }) => theme.palette.primary.main};
`;
