import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import RoomCard from './RoomCard';
import RoomDetailsModal from '../../RoomDetailsModal/RoomDetailsModal';

export const NETWORK_ERROR_MAIN_TEXT = 'Rooms Failed to Display';
export const NETWORK_ERROR_SUB_TEXT = 'Please check your internet connection';
export const SPINNER = 'ROOMS_LIST_SPINNER';

const Container = styled('div')`
  grid-area: rooms_list;
  border-radius: 5px;
  border: 1px solid #8e8e8e;
  background-color: #ececec;
  overflow-y: auto;
  padding: 25px 25px;
`;

const NoRoomsContainer = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #666666;
`;

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 15px;
`;

const GeneralAreaSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
`;

const RoomsSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const SectionHeader = styled(Typography)``;

const RoomsList = ({
  rooms = [],
  generalAreas = [],
  networkError,
  isAnyFetchLoading,
}) => {
  const location = useLocation();
  const [selectedRoom, setSelectedRoom] = useState(null);

  const renderRoomCard = (room) => {
    return (
      <div key={room.number}>
        <Link
          to={{
            pathname: `/rooms/${room.id}`,
            state: { background: location },
          }}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <RoomCard
            room={room}
            incompleteTaskCount={room.incompleteTaskCount}
            hasTask={room.hasTask}
            onClick={() => setSelectedRoom(room)}
          />
        </Link>
      </div>
    );
  };

  let RoomsListWithLoading;
  if (isAnyFetchLoading) {
    RoomsListWithLoading = (
      <NoRoomsContainer>
        <CircularProgress data-testid={SPINNER} />{' '}
      </NoRoomsContainer>
    );
  } else if (networkError) {
    RoomsListWithLoading = (
      <NoRoomsContainer>
        <Typography variant="h3"> {NETWORK_ERROR_MAIN_TEXT}</Typography>
        <Typography variant="body1">{NETWORK_ERROR_SUB_TEXT}</Typography>
      </NoRoomsContainer>
    );
  } else if (rooms) {
    RoomsListWithLoading = (
      <GridContainer>
        {generalAreas.length > 0 && (
          <>
            <SectionHeader variant="h5">General Areas</SectionHeader>
            <GeneralAreaSection>
              {generalAreas.map((generalArea) => {
                return renderRoomCard(generalArea);
              })}
            </GeneralAreaSection>
            <SectionHeader variant="h5">Rooms</SectionHeader>
          </>
        )}

        <RoomsSection>
          {rooms.map((room) => {
            return renderRoomCard(room);
          })}
        </RoomsSection>
      </GridContainer>
    );
  }

  return (
    <Container>
      {RoomsListWithLoading}
      <RoomDetailsModal room={selectedRoom} open={!!selectedRoom} />
    </Container>
  );
};

RoomsList.propTypes = {
  rooms: PropTypes.array,
  isAnyFetchLoading: PropTypes.bool,
  networkError: PropTypes.string,
};

export default RoomsList;
