import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useStore } from 'src/context/StoreContext';

import RoomDetailsHeader from './components/RoomDetailsHeader';
import RoomDetailsMenu from './components/RoomDetailsMenu';
import RoomDetailsTaskListContainer from './components/RoomDetailsTaskListContainer';
import CreateEditTaskScreen from '../../Tasks/CreateEditTaskScreen/CreateEditTaskScreen';

const Container = styled('div')`
  display: grid;
  outline: 0;
  background-color: #ffffff;
  overflow: hidden;
  grid-template-rows: auto 1fr 75px;
  grid-template-areas:
    'header'
    'taskList'
    'menu';

  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 75%;
    width: 80%;
    max-width: 1400px;
    grid-template-columns: minmax(180px, 300px) minmax(500px, 1fr);
    grid-template-rows: auto minmax(auto, 1fr);
    grid-template-areas:
      'header header'
      'menu taskList';
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 800px;
  }
`;

const HeaderContainer = styled('div')`
  grid-area: header;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
`;
const MenuContainer = styled('div')`
  grid-area: menu;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

const RoomDetailsModal = () => {
  const history = useHistory();
  const store = useStore();
  const [taskHistoryOpen, setTaskHistoryOpen] = useState(false);
  const [createTaskOpen, setCreateTaskOpen] = useState(false);
  const [editTaskOpen, setEditTaskOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [isRoomBlock, setIsRoomBlock] = useState(false);

  const { id } = useParams();
  const room = store.roomStore.rooms.get(id);
  const location = useLocation();
  const background = location.state && location.state.background;

  const resetState = () => {
    setIsRoomBlock(false);
    setCreateTaskOpen(false);
    setEditTaskOpen(false);
    setTaskToEdit(null);
    setTaskHistoryOpen(false);
  };

  if (!room) {
    return null;
  }

  const onClose = (e) => {
    resetState();
    e.stopPropagation();
    history.goBack();
  };

  const {
    translatedName,
    doNotDisturb,
    vacancyStatus,
    cleanStatus,
    incompleteTasks,
    completedTodayTasks,
    completedBeforeTodayTasks,
    isGeneralArea,
  } = room;

  const getCreateEditTask = () => {
    if (createTaskOpen || editTaskOpen) {
      return (
        <CreateEditTaskScreen
          blockFromRoomDetails={isRoomBlock}
          task={editTaskOpen ? taskToEdit : null}
          room={room}
          handleGoBack={() => {
            setIsRoomBlock(false);
            if (editTaskOpen) {
              setTaskToEdit(null);
              setEditTaskOpen(false);
            } else {
              setCreateTaskOpen(false);
            }
          }}
        />
      );
    }
    return null;
  };

  const getTaskListContainer = () => {
    if (!createTaskOpen && !editTaskOpen) {
      return (
        <RoomDetailsTaskListContainer
          setTaskHistoryOpen={setTaskHistoryOpen}
          taskHistoryOpen={taskHistoryOpen}
          setTaskToEdit={setTaskToEdit}
          setEditTaskOpen={setEditTaskOpen}
          incompleteTasks={incompleteTasks}
          completedTodayTasks={completedTodayTasks}
          completedBeforeTodayTasks={completedBeforeTodayTasks}
          setCreateTaskOpen={setCreateTaskOpen}
        />
      );
    }
    return null;
  };

  return (
    <StyledModal open={!!background} onClose={onClose}>
      <Container>
        <HeaderContainer>
          <RoomDetailsHeader
            number={translatedName}
            doNotDisturb={doNotDisturb}
            vacancyStatus={vacancyStatus}
            cleanStatus={cleanStatus}
            isGeneralArea={isGeneralArea}
          />
        </HeaderContainer>
        <MenuContainer>
          <RoomDetailsMenu
            setCreateTaskOpen={setCreateTaskOpen}
            setIsRoomBlock={setIsRoomBlock}
            room={room}
            onClose={onClose}
            isRoomBlock={isRoomBlock}
          />
        </MenuContainer>
        {getCreateEditTask()}
        {getTaskListContainer()}
      </Container>
    </StyledModal>
  );
};

RoomDetailsModal.propTypes = {
  room: PropTypes.shape({
    number: PropTypes.string,
    doNotDisturb: PropTypes.bool,
    vacancyStatus: PropTypes.string,
    cleanStatus: PropTypes.string,
    incompleteTasks: PropTypes.array,
    completedTodayTasks: PropTypes.array,
  }),
};

export default RoomDetailsModal;
