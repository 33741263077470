import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'src/utils/useBreakpoints';
import { ReactComponent as MarkAsCleanIcon } from 'src/assets/icons/clean.svg';
import { ReactComponent as MarkAsDirtyIcon } from 'src/assets/icons/dirty.svg';
import { ReactComponent as BlockRoomIcon } from 'src/assets/icons/blocked.svg';
import { ReactComponent as DoNotDisturbIcon } from 'src/assets/icons/do_not_disturb.svg';
import { ReactComponent as RoomsIcon } from 'src/assets/icons/rooms.svg';
import { useStore } from 'src/context/StoreContext';
import { CLEAN_STATUS, VACANCY_STATUS, COLORS } from 'src/models/RoomStore';
import { COMPLETE_STATUS } from 'src/models/TaskStore';
import { useConfirmationModal } from 'src/components/ConfirmationModal';
import { TRANSLATION_KEYS } from 'src/constants/translations';

import {
  MARK_AS_CLEAN,
  MARK_AS_DIRTY,
  BLOCK_ROOM,
  TURN_ON_DO_NOT_DISTURB,
  TURN_OFF_DO_NOT_DISTURB,
  CLOSE,
  MARK_AS_VACANT,
  MARK_AS_STAYOVER,
  MARK_AS_CHECKOUT,
  UNBLOCK_CLEAN_ROOM,
  UNBLOCK_DIRTY_ROOM,
} from './RoomDetailStrings';
import RoomDetailsMenuItem from './RoomDetailsMenuItem';

const Container = styled('div')`
  display: grid;
  grid-template-rows: auto 100px;
  height: 100%;
`;

const MobileContainer = styled('div')`
  height: 50vh;
  max-height: 250px;
  padding: 15px;
  background-color: #ffffff;
  display: grid;
  grid-template-rows: repeat(autofit, auto);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const MobileMenu = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
`;

const CloseButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(Button)`
  width: 60%;
  height: 37px;
`;

const RoomDetailsMenu = ({
  room,
  onClose,
  setCreateTaskOpen,
  setIsRoomBlock,
  isRoomBlock,
}) => {
  const { t } = useTranslation();
  const { roomStore, taskStore } = useStore();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isSmallScreen } = useBreakpoints();
  const { showConfirmationModal } = useConfirmationModal();

  const CleanStatusMenuItem = () => {
    if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
      return (
        <RoomDetailsMenuItem
          title={UNBLOCK_CLEAN_ROOM}
          Icon={MarkAsCleanIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            const updateRoom = async () => {
              await roomStore.updateRoom({
                roomId: room.id,
                options: {
                  body: {
                    cleanStatus: CLEAN_STATUS.CLEAN,
                    vacancyStatus: VACANCY_STATUS.VACANT,
                  },
                  shouldThrowError: true,
                },
              });
            };

            const promises = [];
            if (room.incompleteTasks.length > 0) {
              showConfirmationModal({
                variant: 'confirm-only',
                prompt: 'Do you want to complete the tasks?',
                onConfirm: async () => {
                  room.incompleteTasks.forEach((task) => {
                    const taskUpdates = {
                      status: COMPLETE_STATUS,
                    };

                    promises.push(
                      taskStore.patchTask({
                        taskId: task.id,
                        options: { body: { task: taskUpdates } },
                        shouldThrowError: true,
                      })
                    );
                  });
                },
                onClose: () => {
                  showConfirmationModal({
                    variant: 'loading-error-only',
                    onConfirm: async () => {
                      await Promise.all(promises);
                      await updateRoom();
                    },
                  });
                },
              });
            } else {
              showConfirmationModal({
                variant: 'loading-error-only',
                onConfirm: async () => {
                  await updateRoom();
                },
              });
            }
          }}
        />
      );
    }
    if (room.cleanStatus === CLEAN_STATUS.CLEAN) {
      return (
        <RoomDetailsMenuItem
          title={MARK_AS_DIRTY}
          Icon={MarkAsDirtyIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            const updateRoomToDirty = async ({
              manualNotificationType,
              shouldThrowError = true,
            }) => {
              await roomStore.updateRoom({
                roomId: room.id,
                options: {
                  body: { cleanStatus: CLEAN_STATUS.DIRTY },
                  manualNotificationType,
                },
                shouldThrowError,
                errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.DIRTY}. Please try again.`,
              });
            };

            if (room.vacancyStatus === VACANCY_STATUS.STAYOVER) {
              showConfirmationModal({
                variant: 'loading-error-only',
                onConfirm: async () => {
                  await updateRoomToDirty({
                    manualNotificationType: 'stayover_room_requests_cleaning',
                  });
                },
              });
            } else {
              showConfirmationModal({
                variant: 'loading-error-only',
                onConfirm: async () => {
                  await updateRoomToDirty({});
                },
              });
            }
          }}
        />
      );
    }
    return (
      <RoomDetailsMenuItem
        title={MARK_AS_CLEAN}
        Icon={MarkAsCleanIcon}
        onClick={() => {
          setIsDrawerOpen(false);

          showConfirmationModal({
            variant: 'loading-error-only',
            onConfirm: async () => {
              await roomStore.updateRoom({
                roomId: room.id,
                options: {
                  body: { cleanStatus: CLEAN_STATUS.CLEAN },
                },
                shouldThrowError: true,
                errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.CLEAN}. Please try again.`,
              });
            },
          });
        }}
      />
    );
  };

  const BlockMenuItem = () => {
    if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
      return (
        <RoomDetailsMenuItem
          title={UNBLOCK_DIRTY_ROOM}
          Icon={MarkAsDirtyIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            const updateRoom = async () => {
              await roomStore.updateRoom({
                roomId: room.id,
                options: {
                  body: {
                    cleanStatus: CLEAN_STATUS.DIRTY,
                    vacancyStatus: VACANCY_STATUS.VACANT,
                  },
                  shouldThrowError: true,
                },
              });
            };

            const promises = [];
            if (room.incompleteTasks.length > 0) {
              showConfirmationModal({
                variant: 'confirm-only',
                prompt: 'Do you want to complete the tasks?',
                onConfirm: async () => {
                  room.incompleteTasks.forEach((task) => {
                    const taskUpdates = {
                      status: COMPLETE_STATUS,
                    };

                    promises.push(
                      taskStore.patchTask({
                        taskId: task.id,
                        options: { body: { task: taskUpdates } },
                        shouldThrowError: true,
                      })
                    );
                  });
                },
                onClose: () => {
                  showConfirmationModal({
                    variant: 'loading-error-only',
                    onConfirm: async () => {
                      await Promise.all(promises);
                      await updateRoom();
                    },
                  });
                },
              });
            } else {
              showConfirmationModal({
                variant: 'loading-error-only',
                onConfirm: async () => {
                  await updateRoom();
                },
              });
            }
          }}
        />
      );
    }
    return (
      <RoomDetailsMenuItem
        title={BLOCK_ROOM}
        Icon={BlockRoomIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          if (room.incompleteTasks.length === 0) {
            setCreateTaskOpen(true);
            setIsRoomBlock(true);
          } else {
            showConfirmationModal({
              variant: 'loading-error-only',
              onConfirm: async () => {
                await roomStore.updateRoom({
                  roomId: room.id,
                  options: {
                    body: { vacancyStatus: VACANCY_STATUS.BLOCKED },
                    shouldThrowError: true,
                  },
                });
              },
            });
          }
        }}
        disabled={isRoomBlock}
      />
    );
  };

  const DoNotDisturbMenuItem = () => {
    if (room.doNotDisturb) {
      return (
        <RoomDetailsMenuItem
          title={TURN_OFF_DO_NOT_DISTURB}
          Icon={DoNotDisturbIcon}
          onClick={() => {
            setIsDrawerOpen(false);

            showConfirmationModal({
              variant: 'loading-error-only',
              onConfirm: async () => {
                await roomStore.updateRoom({
                  roomId: room.id,
                  options: {
                    body: { doNotDisturb: false },
                  },
                  shouldThrowError: true,
                  errorNotificationText: `Unable to turn off Do not Disturb for room ${room.number}. Please try again.`,
                });
              },
            });
          }}
          disabled={room.vacancyStatus !== VACANCY_STATUS.STAYOVER}
          selectedColor={COLORS.DO_NOT_DISTURB}
        />
      );
    }

    return (
      <RoomDetailsMenuItem
        title={TURN_ON_DO_NOT_DISTURB}
        Icon={DoNotDisturbIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          showConfirmationModal({
            variant: 'loading-error-only',
            onConfirm: async () => {
              await roomStore.updateRoom({
                roomId: room.id,
                options: {
                  body: { doNotDisturb: true },
                },
                shouldThrowError: true,
                errorNotificationText: `Unable to turn on Do not Disturb for room ${room.number}. Please try again.`,
              });
            },
          });
        }}
        disabled={room.vacancyStatus !== VACANCY_STATUS.STAYOVER}
      />
    );
  };

  const StayoverMenuItem = () => {
    const STAYOVER = (
      <RoomDetailsMenuItem
        title={MARK_AS_STAYOVER}
        Icon={RoomsIcon}
        onClick={() => {
          setIsDrawerOpen(false);

          const updateRoom = async ({ shouldThrowError = true } = {}) => {
            await roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { vacancyStatus: VACANCY_STATUS.STAYOVER },
              },
              shouldThrowError,
              errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.STAYOVER}. Please try again.`,
            });
          };
          if (room.vacancyStatus === VACANCY_STATUS.CHECKOUT) {
            showConfirmationModal({
              title: t(TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_TITLE),
              prompt: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_PROMPT,
                {
                  roomNumber: room.number,
                }
              ),
              successPrompt: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_EXTENDED_STAY_SUCCESS_PROMPT,
                {
                  roomNumber: room.number,
                }
              ),
              onConfirm: updateRoom,
            });
          } else {
            showConfirmationModal({
              variant: 'loading-error-only',
              onConfirm: async () => {
                await updateRoom({});
              },
            });
          }
        }}
      />
    );
    const VACANT = (
      <RoomDetailsMenuItem
        title={MARK_AS_VACANT}
        Icon={RoomsIcon}
        onClick={() => {
          setIsDrawerOpen(false);

          const updateRoom = async ({ shouldThrowError = true } = {}) => {
            await roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { vacancyStatus: VACANCY_STATUS.VACANT },
              },
              shouldThrowError,
              errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.VACANT}. Please try again.`,
            });
          };

          if (room.vacancyStatus === VACANCY_STATUS.STAYOVER) {
            showConfirmationModal({
              title: t(TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_TITLE),
              prompt: t(TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_PROMPT, {
                roomNumber: room.number,
              }),
              successPrompt: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_VACANT_SUCCESS_PROMPT,
                {
                  roomNumber: room.number,
                }
              ),
              onConfirm: updateRoom,
            });
          } else {
            showConfirmationModal({
              variant: 'loading-error-only',
              onConfirm: async () => {
                await updateRoom({});
              },
            });
          }
        }}
      />
    );
    const CHECKOUT = (
      <RoomDetailsMenuItem
        title={MARK_AS_CHECKOUT}
        Icon={RoomsIcon}
        onClick={() => {
          setIsDrawerOpen(false);

          const updateRoom = async ({ shouldThrowError = true } = {}) => {
            await roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { vacancyStatus: VACANCY_STATUS.CHECKOUT },
              },
              shouldThrowError,
              errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.CHECKOUT}. Please try again.`,
            });
          };

          if (room.vacancyStatus === VACANCY_STATUS.STAYOVER) {
            showConfirmationModal({
              title: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_TITLE
              ),
              prompt: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_PROMPT,
                {
                  roomNumber: room.number,
                }
              ),
              successPrompt: t(
                TRANSLATION_KEYS.CONFIRMATION_MODAL_EARLY_CHECKOUT_SUCCESS_PROMPT,
                {
                  roomNumber: room.number,
                }
              ),
              onConfirm: updateRoom,
            });
          } else {
            showConfirmationModal({
              variant: 'loading-error-only',
              onConfirm: async () => {
                await updateRoom({});
              },
            });
          }
        }}
      />
    );

    if (room.vacancyStatus === VACANCY_STATUS.VACANT) {
      return (
        <>
          {STAYOVER}
          {CHECKOUT}
        </>
      );
    }

    if (room.vacancyStatus === VACANCY_STATUS.CHECKOUT) {
      return (
        <>
          {STAYOVER}
          {VACANT}
        </>
      );
    }
    return (
      <>
        {CHECKOUT}
        {VACANT}
      </>
    );
  };

  return isSmallScreen ? (
    <>
      <MobileMenu>
        <Button color="grey" onClick={onClose}>
          Back
        </Button>

        <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
          Room Actions
        </Button>
      </MobileMenu>
      <Drawer
        style={{
          zIndex: 9999,
        }}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        disableSwipeToOpen={false}
      >
        <MobileContainer>
          <CleanStatusMenuItem />
          <BlockMenuItem />
          {!room.isGeneralArea && (
            <>
              <DoNotDisturbMenuItem />
              <StayoverMenuItem />
            </>
          )}
        </MobileContainer>
      </Drawer>
    </>
  ) : (
    <Container>
      <div>
        <CleanStatusMenuItem />
        <BlockMenuItem />
        {!room.isGeneralArea && (
          <>
            <DoNotDisturbMenuItem />
            <StayoverMenuItem />
          </>
        )}
      </div>

      <CloseButtonContainer>
        <CloseButton variant="outlined" onClick={onClose}>
          {CLOSE}
        </CloseButton>
      </CloseButtonContainer>
    </Container>
  );
};

RoomDetailsMenu.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    cleanStatus: PropTypes.oneOf(
      Object.keys(CLEAN_STATUS).map((key) => CLEAN_STATUS[key])
    ),
    vacancyStatus: PropTypes.oneOf(
      Object.keys(VACANCY_STATUS).map((key) => VACANCY_STATUS[key])
    ),
    doNotDisturb: PropTypes.bool,
    incompleteTasks: PropTypes.array,
  }).isRequired,
  onClose: PropTypes.func,
  isRoomBlock: PropTypes.bool,
  setIsRoomBlock: PropTypes.func,
  setCreateTaskOpen: PropTypes.func,
};

export default RoomDetailsMenu;
