import React from 'react';
import { useTheme } from '@emotion/react';

import { ReactComponent as FloorIcon } from 'src/assets/icons/custom-floor.svg';

export const GeneralAreaIcon = ({
  iconName = 'default',
  size = 20,
  color,
  style,
  presetStyle,
}: {
  iconName: string;
  size?: number;
  color?: React.CSSProperties['color'];
  style?: React.CSSProperties;
  presetStyle?: 'RoomCard';
}) => {
  const theme = useTheme();
  const fillColor = color ?? theme.palette.black.main;

  const defaultStyle = {
    width: size,
    height: size,
    fontSize: size,
    color: fillColor,
  };
  let PRESET_STYLES = {};

  switch (iconName) {
    case 'floor':
      PRESET_STYLES = {
        RoomCard: {},
      };

      return (
        <FloorIcon
          style={{
            ...defaultStyle,
            ...PRESET_STYLES[presetStyle],
            ...style,
          }}
        />
      );
    case 'pool':
      PRESET_STYLES = {
        RoomCard: {},
      };
      return (
        <span
          class={'material-icons'}
          style={{ ...defaultStyle, ...PRESET_STYLES[presetStyle], ...style }}
        >
          pool
        </span>
      );
    default:
      PRESET_STYLES = {
        RoomCard: {},
      };

      return (
        <span
          class={'material-icons'}
          style={{ ...defaultStyle, ...PRESET_STYLES[presetStyle], ...style }}
        >
          domain_add
        </span>
      );
  }
};

export default GeneralAreaIcon;
